export const translationExceptions: any = {
  '/auto/de': {
    Rail: 'Bahn',
    'Airports & Suppliers': 'Flughafen & Anbieter',
    'Travel news': 'Reise Nachrichten',
    'Airport & Travel Supplier Policies': 'Flughafen & Anbieter',
    'Country entry restrictions': 'Länder Einreisebeschränkungen',
    'Supplier list is subject to change': 'Die Liste der Dienstleister kann sich jederzeit ändern',
  },
  '/auto/fr': {
    // eslint-disable-next-line quotes
    Europe: "L'Europe",
    Low: 'Faible',
  },
  '/auto/es': {
    MENA: 'MONA',
  },
};
