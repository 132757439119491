/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, MutableRefObject } from 'react';

function useClickOutsideHandler(ref: MutableRefObject<any>, onOutsideClick: () => void): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, ref]);
}

export default useClickOutsideHandler;
