import {
  POLICY_AIRPORTS_URI,
  POLICY_CAR_RENTALS_URI,
  POLICY_HOTEL_CHAINS_URI,
  POLICY_RAIL_URI,
  POLICY_AIRLINE_CARRIERS_URI,
} from 'constants/pageUris';

export const tabs = [
  {
    dataUri: POLICY_AIRPORTS_URI,
    text: 'Airports',
  },
  {
    dataUri: POLICY_AIRLINE_CARRIERS_URI,
    text: 'Airlines',
  },
  {
    dataUri: POLICY_CAR_RENTALS_URI,
    text: 'Car rentals',
  },
  {
    dataUri: POLICY_HOTEL_CHAINS_URI,
    text: 'Hotels',
  },
  {
    dataUri: POLICY_RAIL_URI,
    text: 'Rail',
  },
];
