import {
  INDEX_PAGE_URI,
  INTERNATIONAL_PAGE_URI,
  COVID_19_MAP_PAGE_URI,
  POLICY_AIRLINE_CARRIERS_URI,
  POLICY_AIRPORTS_URI,
  POLICY_HOTEL_CHAINS_URI,
  POLICY_CAR_RENTALS_URI,
  POLICY_RAIL_URI,
  REGION_STATES_URI,
  REGION_AMERICAS_URI,
  REGION_EUROPE_URI,
  REGION_AFRICA_URI,
  REGION_MIDDLE_EAST_URI,
  REGION_ASIA_URI,
  NEED_TO_KNOW,
} from './pageUris';

export const menuTabIndexByPathMap: Record<string, { [key: string]: number }> = {
  mainMenu: {
    [INDEX_PAGE_URI]: 0,
    [INTERNATIONAL_PAGE_URI]: 0,

    [COVID_19_MAP_PAGE_URI]: 1,

    [NEED_TO_KNOW]: 2,

    [POLICY_AIRLINE_CARRIERS_URI]: 3,
    [POLICY_AIRPORTS_URI]: 3,
    [POLICY_HOTEL_CHAINS_URI]: 3,
    [POLICY_CAR_RENTALS_URI]: 3,
    [POLICY_RAIL_URI]: 3,
  },
  subMenu: {
    [INDEX_PAGE_URI]: 0,
    [INTERNATIONAL_PAGE_URI]: 0,
    [REGION_AMERICAS_URI]: 0,
    [REGION_EUROPE_URI]: 0,
    [REGION_AFRICA_URI]: 0,
    [REGION_MIDDLE_EAST_URI]: 0,
    [REGION_ASIA_URI]: 0,

    [NEED_TO_KNOW]: 0,

    [POLICY_AIRPORTS_URI]: 0,
    [POLICY_AIRLINE_CARRIERS_URI]: 1,
    [POLICY_CAR_RENTALS_URI]: 2,
    [POLICY_HOTEL_CHAINS_URI]: 3,
    [POLICY_RAIL_URI]: 4,
  },
  airlinesMenu: {
    [POLICY_AIRLINE_CARRIERS_URI]: 0,
  },
  regionMenu: {
    [INTERNATIONAL_PAGE_URI]: 0,
    [REGION_STATES_URI]: 1,
    [REGION_AMERICAS_URI]: 2,
    [REGION_EUROPE_URI]: 3,
    [REGION_AFRICA_URI]: 4,
    [REGION_MIDDLE_EAST_URI]: 5,
    [REGION_ASIA_URI]: 6,
  },
  hotelsMenu: {
    [POLICY_HOTEL_CHAINS_URI]: 0,
  },
};
