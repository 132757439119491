export const INDEX_PAGE_URI = '/';

export const INTERNATIONAL_PAGE_URI = '/international';
export const REGION_STATES_URI = `${INTERNATIONAL_PAGE_URI}/usa`;
export const REGION_AMERICAS_URI = `${INTERNATIONAL_PAGE_URI}/americas`;
export const REGION_EUROPE_URI = `${INTERNATIONAL_PAGE_URI}/europe`;
export const REGION_AFRICA_URI = `${INTERNATIONAL_PAGE_URI}/africa`;
export const REGION_MIDDLE_EAST_URI = `${INTERNATIONAL_PAGE_URI}/mena`;
export const REGION_ASIA_URI = `${INTERNATIONAL_PAGE_URI}/asia`;

export const COVID_19_MAP_PAGE_URI = '/covid-19-map';
export const POLICY_AIRLINE_CARRIERS_URI = '/travel-policies/airlines/all';

export const POLICY_AIRPORTS_URI = '/travel-policies/airports/all';
export const POLICY_HOTEL_CHAINS_URI = '/travel-policies/hotels/all';
export const POLICY_CAR_RENTALS_URI = '/travel-policies/car-rentals/all';
export const POLICY_RAIL_URI = '/travel-policies/rail/all';

export const NEED_TO_KNOW = '/need-to-know';
