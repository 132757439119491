/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';
import { getTranstlatedText } from 'helpers/getTranslatedText';
import React from 'react';
import { withCookies } from 'react-cookie';
import { IMenuItemProps } from '../types';

const MenuItem = (props: IMenuItemProps): React.ReactElement<IMenuItemProps> => {
  const { uri, className, itemName, navigate, cookies } = props;
  const onMenuItemClick = () => navigate(uri);

  const lang = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

  return (
    <a href={undefined} className={className} role="button" onClick={onMenuItemClick}>
      <span>{getTranstlatedText(itemName, lang)}</span>
    </a>
  );
};

export default withCookies(MenuItem);
