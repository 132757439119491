/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import {
  INDEX_PAGE_URI,
  COVID_19_MAP_PAGE_URI,
  INTERNATIONAL_PAGE_URI,
  REGION_STATES_URI,
  REGION_MIDDLE_EAST_URI,
  REGION_AFRICA_URI,
  REGION_EUROPE_URI,
  REGION_AMERICAS_URI,
  REGION_ASIA_URI,
  POLICY_AIRLINE_CARRIERS_URI,
  POLICY_AIRPORTS_URI,
  POLICY_HOTEL_CHAINS_URI,
  POLICY_CAR_RENTALS_URI,
  POLICY_RAIL_URI,
  NEED_TO_KNOW,
} from 'constants/pageUris';
import useClickOutsideHandler from '@hooks/useOutsideClickHandler';
import { getTranstlatedText } from 'helpers/getTranslatedText';
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';
import { withCookies } from 'react-cookie';
import { IProps, IMenuItemProps } from './types';
import styles from './MobileMenu.module.css';
import MenuItem from './MenuItem/MenuItem';

const uriToMenuItemMap: { [key: string]: string } = {
  [INDEX_PAGE_URI]: 'Country entry restrictions',
  [INTERNATIONAL_PAGE_URI]: 'Country entry restrictions',
  [REGION_STATES_URI]: 'Country entry restrictions',
  [REGION_MIDDLE_EAST_URI]: 'Country entry restrictions',
  [REGION_AFRICA_URI]: 'Country entry restrictions',
  [REGION_EUROPE_URI]: 'Country entry restrictions',
  [REGION_AMERICAS_URI]: 'Country entry restrictions',
  [REGION_ASIA_URI]: 'Country entry restrictions',
  [COVID_19_MAP_PAGE_URI]: 'COVID-19 maps',
  [NEED_TO_KNOW]: 'Travel news',
  [POLICY_AIRLINE_CARRIERS_URI]: 'Airlines',
  [POLICY_AIRPORTS_URI]: 'Airports',
  [POLICY_HOTEL_CHAINS_URI]: 'Hotels',
  [POLICY_RAIL_URI]: 'Rail',
  [POLICY_CAR_RENTALS_URI]: 'Car Rentals',
};

function MobileMenu(props: IProps): React.ReactElement {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { uri, staticUri, cookies } = props;
  const menuRef = useRef(null);

  const lang = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

  useClickOutsideHandler(menuRef, () => setIsMenuOpen(false));

  const isItemSelected = (uri: string): boolean =>
    uriToMenuItemMap[props.uri] === uriToMenuItemMap[uri];
  const getMenuItemProps = (
    itemName: string,
    uri: string,
    isSelectable = true,
  ): IMenuItemProps => ({
    ...props,
    uri,
    itemName,
    className: isItemSelected(uri) && isSelectable ? styles.menuItemSelected : styles.menuItem,
  });
  const getMenuSubItemsProps = (itemName: string, uri: string): IMenuItemProps => ({
    ...props,
    uri,
    itemName,
    className: isItemSelected(uri)
      ? `${styles.menuSubItemSelected} ${styles.menuSubItem}`
      : styles.menuSubItem,
  });

  return !isMenuOpen ? (
    <button className={styles.closedMenuControl} onClick={() => setIsMenuOpen(true)} type="button">
      <span>{getTranstlatedText(uriToMenuItemMap[uri === '/' ? staticUri : uri], lang)}</span>
      <div className={styles.menuIcon} />
    </button>
  ) : (
    <div ref={menuRef} className={styles.menuWrapper}>
      <div className={styles.openedMenu}>
        <button
          className={styles.openedMenuHeader}
          type="button"
          onClick={() => setIsMenuOpen(false)}
        >
          <span className={`${styles.menuIcon} ${styles.opened}`} />
        </button>
        <MenuItem {...getMenuItemProps('Country entry restrictions', INDEX_PAGE_URI)} />
        <MenuItem {...getMenuItemProps('COVID-19 maps', COVID_19_MAP_PAGE_URI)} />
        <MenuItem {...getMenuItemProps('Travel news', NEED_TO_KNOW)} />
        <MenuItem {...getMenuItemProps('Airports & Suppliers', POLICY_AIRPORTS_URI, false)} />
        <MenuItem {...getMenuSubItemsProps('Airports', POLICY_AIRPORTS_URI)} />
        <MenuItem {...getMenuSubItemsProps('Airlines', POLICY_AIRLINE_CARRIERS_URI)} />
        <MenuItem {...getMenuSubItemsProps('Car Rentals', POLICY_CAR_RENTALS_URI)} />
        <MenuItem {...getMenuSubItemsProps('Hotels', POLICY_HOTEL_CHAINS_URI)} />
        <MenuItem {...getMenuSubItemsProps('Rail', POLICY_RAIL_URI)} />
      </div>
    </div>
  );
}

export default withCookies(MobileMenu);
