import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Sticky from 'react-stickynode';

import { handleMenuTabSelect } from '@components/helpers';
import MobileMenu from '@components/MobileMenu/MobileMenu';
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';

import { menuTabIndexByPathMap } from 'constants/menuTabIndexByPathMap';
import {
  INDEX_PAGE_URI,
  COVID_19_MAP_PAGE_URI,
  POLICY_AIRPORTS_URI,
  NEED_TO_KNOW,
} from 'constants/pageUris';
import { STICKY_DISABLED_URIS } from 'constants/stickyDisabledUris';

import { classNames } from 'utils/classNames';
import { getTranstlatedText } from 'helpers/getTranslatedText';

import MapIcon from '../../../static/covid_map.svg';
import CountryRestrictionsIcon from '../../../static/countries_restrictions.svg';
import NeedToKnowIcon from '../../../static/what_you_need_to_know.svg';
import TravelPoliciesIcon from '../../../static/travel_policies.svg';

import { IProps, IMainMenuState } from './types';

import { PoliciesSubMenu } from './components/PoliciesSubMenu';

import styles from './MainMenu.module.css';

class MainMenu extends Component<IProps, IMainMenuState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      lang: this.props.cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME),
    };
  }

  isStickyMenuEnabled(uri: string): boolean {
    return STICKY_DISABLED_URIS.every(item => item !== uri);
  }

  render(): React.ReactElement {
    const {
      props: { children, uri, staticUri },
    } = this;

    const { mainMenu } = menuTabIndexByPathMap;

    return (
      <>
        <div className={styles.desktopMenuWrapper}>
          <Tabs
            selectedTabClassName={styles.tabsSelected}
            selectedIndex={mainMenu[uri === '/' ? staticUri : uri]}
            onSelect={handleMenuTabSelect(this.props)}
          >
            <TabList className={styles.tabList}>
              <Tab
                className={classNames([styles.tabsTab, styles.tabsTab1])}
                data-uri={INDEX_PAGE_URI}
              >
                <img src={CountryRestrictionsIcon} alt="country icon" />

                <span className={styles.tabsTabText}>
                  {getTranstlatedText('Country entry restrictions', this.state.lang)}
                </span>
              </Tab>
              <Tab
                className={classNames([styles.tabsTab, styles.tabsTab2])}
                data-uri={COVID_19_MAP_PAGE_URI}
              >
                <img src={MapIcon} alt="covid map icon" />

                <span className={styles.tabsTabText}>COVID-19 maps</span>
              </Tab>
              <Tab
                className={classNames([styles.tabsTab, styles.tabsTab3])}
                data-uri={NEED_TO_KNOW}
              >
                <img src={NeedToKnowIcon} alt="need to know icon" />

                <span className={styles.tabsTabText}>
                  {getTranstlatedText('Travel news', this.state.lang)}
                </span>
              </Tab>
              <Tab
                className={classNames([styles.tabsTab, styles.tabsTab4])}
                data-uri={POLICY_AIRPORTS_URI}
              >
                <img src={TravelPoliciesIcon} alt="travel policies icon" />

                <span className={styles.tabsTabText}>
                  {getTranstlatedText('Airports & Suppliers', this.state.lang)}
                </span>
              </Tab>
            </TabList>
            <Sticky
              enabled={this.isStickyMenuEnabled(staticUri)}
              top={0}
              innerClass={styles.stickyWrapper}
              activeClass={styles.stickyAciveWrapper}
            >
              <TabPanel selectedClassName={styles.tabPanelSelected} className={styles.searchBar}>
                {children}
              </TabPanel>
              <TabPanel selectedClassName={styles.tabPanelSelected} className={styles.tabPanel}>
                {children}
              </TabPanel>
              <TabPanel selectedClassName={styles.tabPanelSelected}>{children}</TabPanel>
              <TabPanel selectedClassName={styles.tabPanelSelected} className={styles.tabPanel}>
                <PoliciesSubMenu
                  {...this.props}
                  handleMenuTabSelect={handleMenuTabSelect(this.props)}
                />
                {children}
              </TabPanel>
            </Sticky>
          </Tabs>
          {uri === NEED_TO_KNOW && <div className={styles.line} />}
        </div>
        <div className={styles.mobileMenuWrapper}>
          <MobileMenu uri={uri} navigate={this.props.navigate} staticUri={staticUri} />
          {children}
        </div>
      </>
    );
  }
}

export default withCookies(MainMenu);
