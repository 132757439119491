import React from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { menuTabIndexByPathMap } from 'constants/menuTabIndexByPathMap';
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';
import { getTranstlatedText } from 'helpers/getTranslatedText';
import { tabs } from './constants';
import tabStyles from '../SubMenuTabs.module.css';

import { IProps } from '../../types';

const { subMenu } = menuTabIndexByPathMap;

type Props = IProps & {
  handleMenuTabSelect: (tabIndex: number, lastIndex: number, event: Event) => void;
  cookies: Cookies | undefined;
};

function PoliciesSubMenu(props: Props): React.ReactElement {
  const { uri, staticUri, handleMenuTabSelect } = props;

  const { cookies } = props;

  const lang = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

  return (
    <Tabs
      selectedTabClassName={tabStyles.tabsSelected}
      selectedIndex={subMenu[uri === '/' ? staticUri : uri]}
      onSelect={handleMenuTabSelect}
    >
      <TabList className={tabStyles.tabList}>
        {tabs.map(({ dataUri, text }) => (
          <Tab className={tabStyles.tabsTab} data-uri={dataUri} key={dataUri + text}>
            {getTranstlatedText(text, lang)}
          </Tab>
        ))}
      </TabList>
      <TabPanel selectedClassName={tabStyles.tabPanelSelected} className={tabStyles.tabPanel} />
      <TabPanel selectedClassName={tabStyles.tabPanelSelected} className={tabStyles.tabPanel} />
      <TabPanel selectedClassName={tabStyles.tabPanelSelected} className={tabStyles.tabPanel} />
      <TabPanel selectedClassName={tabStyles.tabPanelSelected} className={tabStyles.tabPanel} />
      <TabPanel selectedClassName={tabStyles.tabPanelSelected} className={tabStyles.tabPanel} />
    </Tabs>
  );
}

export default withCookies(PoliciesSubMenu);
